
    const projectId = "ef142fcff549ad7b1a88f9b2a3589847"

    // imports that are compatible with vite build
    import { createConfig, waitForTransactionReceipt, switchChain, writeContract, fetchEnsAddress, reconnect, disconnect, getPublicClient, getAccount, watchAccount, fetchEnsName } from "@wagmi/core";
    import { mainnet, zora } from "@wagmi/core/chains";

    import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

    import { formatEther, parseUnits, concat, stringToBytes, toHex, hexToBytes, encodeAbiParameters, parseAbiParameters, decodeFunctionResult, isAddress, InsufficientFundsError } from 'viem'

    export const zsep = {
        id: 999999999,
        name: 'Zora Sepolia',
        network: 'zsep',
        nativeCurrency: {
        decimals: 18,
        name: 'Zora Sepolia',
        symbol: 'ETH'
        },
        rpcUrls: {
            public: { http: ['https://sepolia.rpc.zora.energy'] },
            default: { http: ['https://sepolia.rpc.zora.energy'] }
        },
        blockExplorers: {
            blockscout: { name: 'Blockscout', url: 'https://sepolia.explorer.zora.energy' },
            default: { name: 'Blockscout', url: 'https://sepolia.explorer.zora.energy' }
        },
        contracts: {
        }
    } 

    const chains = [mainnet, zora, zsep];
    const wagmiConfig = defaultWagmiConfig({
      chains,
      projectId,
      metadata: {
        name: 'Friday',
        description: 'Mint this moment',
        url: 'https://mintfriday.xyz',
        icons: ['https://mintfriday.xyz/static/favicon.png']
      }
    })
    reconnect(wagmiConfig)

    const modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeMode: 'light' })
    window.web3modal = modal

    // const connectButton = document.getElementById("connect-button");
    const disconnectButton = document.getElementById("disconnect-button");

    const unwatch = watchAccount(wagmiConfig, { 
        async onChange(account) {

        console.log("eth client updated: ", account);

        if(account.isConnected) { 

            // var ens_name;
            // ens_name = await fetchEnsName({address: account.address, chainId: 1})

            current_address = account.address.toLowerCase();
            // if(ens_name) {

                // current_ens = ens_name

                // connectButton.innerHTML = ens_name.replace(/\.(eth|xyz|art)$/, '')
                // connectButton.href = "/" + ens_name
            
            // } else {
                
                // connectButton.innerHTML = account.address.replace(/^(......).+/,'$1');
                // connectButton.href = "/" + account.address.toLowerCase();    

            // }

            $(disconnectButton).show();

        } else {

            // connectButton.innerHTML = "Connect";

            current_address = null;
            current_ens = null;

            $(disconnectButton).hide();

        }

        // console.log(account) 
        // address: "0x8BF2fFc89C70EfBe245AA81Cc597C7E359925D61", connector: kt {_events: r, _eventsCount: 3, chains: Array(1), options: {…}, storage: {…}, …} isConnected: true, isConnecting: false, isDisconnected: false, isReconnecting: false, status: "connected"

        }
    })

    disconnectButton.addEventListener("click", async function () {
        // $('#menu-items').toggle();
        await disconnect(wagmiConfig)
    });

    let abi721_custom = [{
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "string",
                "name": "comment",
                "type": "string"
            }
        ],
        "name": "mintToWithComment",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    }];
    
    window.on_recipient_change = function(elt) {

        console.log('on_recipient_change', elt, elt.value);

        // let elt = event.target;
        let mint_form = $(elt).parents('.mint-form')[0]

        // clear pending search for those fast typers
        if(ens_resolve_debounce != null) { window.clearTimeout(ens_resolve_debounce); }

        if(!elt.value) {
             $(mint_form).find('.recipient-addr').attr('data-recipient-addr', current_address)
             $(mint_form).find('.recipient-addr').text(current_address.replace(/^(......).+/,'$1'))
            return false;
        }

        if(isAddress(elt.value)) { 
            $(mint_form).find('.recipient-addr').text("✔");
            $(mint_form).find('.recipient-addr').attr('data-recipient-addr', elt.value)
            return false;
        }

        if(!elt.value.match(/\.(eth|xyz|art)$/)) { 
            $(mint_form).find('.recipient-addr').text("––––");
            $(mint_form).find('.recipient-addr').attr('data-recipient-addr', "")
            return false; 
        }

        ens_resolve_debounce = window.setTimeout(async function () { 

            ens_resolve_debounce = null;

            $(mint_form).find('.recipient-addr').text("Searching...")

            let address = await fetchEnsAddress({
                name: elt.value.trim(), chainId: 1
            })

            console.log("Got ens result", address)
            if(address) {
                 $(mint_form).find('.recipient-addr').attr('data-recipient-addr', address)
                 $(mint_form).find('.recipient-addr').text(address.replace(/^(......).+/,'$1'))
            } else {
                 $(mint_form).find('.recipient-addr').text("––––")
                 $(mint_form).find('.recipient-addr').attr('data-recipient-addr', "")
            }

        }, 200);

        return false;


    }

    window.mint_button_click = async function (e) {

        let elt = e.target
        let mint_form = $(elt).parents('.mint-form')[0];

        if($(elt).text() == "Minting not available") { return false; }

        // initial state, also makes sure we're wallet-connected
        let account = await getAccount(wagmiConfig)
        console.log("account is ", account)

        if($(elt).text()=="MINT AGAIN") {

            $('.success-state').hide();
            $('.current-token').hide();
            $(elt).text("MINT");
            $(mint_form).addClass('active');
    
            return false;            

        } else if($('.current-token').is(":visible")) { 

            // request wallet connect here
            $('.current-token').hide();
            $(elt).text("MINT");
            $(mint_form).addClass('active');

            if(! account.isConnected) { web3modal.open(); }

            return false;

        } 

        if(! account.isConnected) { web3modal.open(); return false; }

        let zora_url;
        if($(mint_form).attr('data-collection-chain')=="zsep") {
            zora_url = "https://testnet.zora.co/collect/" + $(mint_form).attr('data-collection-chain') + ":" + $(mint_form).attr('data-collection-address')
        } else {
            zora_url = "https://zora.co/collect/" + $(mint_form).attr('data-collection-chain') + ":" + $(mint_form).attr('data-collection-address')
        }

        // if (!isAddress($(mint_form).find('.recipient-addr').attr('data-recipient-addr'))) {
        //     $(mint_form).find('.mint-msg').html("⚠️ NFT recipient seems incorrect.");
        //     return false;            
        // }

        if($(elt).hasClass("disabled")) { return false; }
        $(elt).addClass("disabled");

        let contract_chain_id
        contract_chain_id = CHAIN_IDS[$(mint_form).attr('data-collection-chain')]
        if (!contract_chain_id) { 
            console.log("Could not get chain id???"); 
            return false; 
        }

        let current_chain_id = account.chainId
        console.log("target chain is " + contract_chain_id + " current chain is " + current_chain_id)

        if(current_chain_id != contract_chain_id) {

            try {
                
                const network = await switchChain(wagmiConfig, {
                    chainId: contract_chain_id,
                })

            } catch (e) {

                if(e.name == "ConnectorNotFoundError") { // shouldn't happen at this stage really
                    console.log("Wallet not connected")
                    $(mint_form).find('.mint-msg').text("⚠️ Lost connection to wallet. Reload this page, tap Connect, and try again.");
                } else if (e.name == "UserRejectedRequestError") { 
                    console.log("User declined")
                } else if (e.name == "SwitchChainError") { 
                    console.log("Mysterious chain switching error", e)
                    $(mint_form).find('.mint-msg').text("⚠️ Couldn't switch to the correct eth network (" + $(mint_form).attr('data-collection-chain') + "). It's possible your wallet does not support this.");
                } else {
                    console.log(e)
                    $(mint_form).find('.mint-msg').text("⚠️ Could not switch network: " + e.name);
                }

                $(elt).removeClass("disabled");
                return false;

            }

        }

        try {

            $(mint_form).find('.mint-msg').text("⏳ Submitting...");

            let tx_hash

            console.log("using abi721_custom");

            tx_hash = await writeContract(wagmiConfig, {
                abi: abi721_custom,
                address: $(mint_form).attr('data-collection-address'),
                functionName: 'mintToWithComment',
                chainId: contract_chain_id,
                args: [ 
                    account.address, 
                    $(mint_form).find('textarea').val()
                ],
                value: $(mint_form).attr('data-price-total')
            })
            console.log("tx res: ", tx_hash)

            $(mint_form).find('.mint-msg').text("⏳ Minting...");

            const data = await waitForTransactionReceipt(wagmiConfig, { chainId: contract_chain_id, hash: tx_hash } )

            const return_value = decodeFunctionResult({
              abi: abi721_custom,
              functionName: 'mintToWithComment',
              data: data.logs[1].data
            })

            console.log("wait for transaction completed. result: ", return_value, data)

            $(mint_form).find('.mint-msg').text("")
            $(mint_form).find('textarea').val("");
            $(mint_form).removeClass("active");

            $('.success-state').show();
            $('.success-state .success-msg').html("Minted! <a href='" + zora_url + "/" + return_value + "' target='_blank'>View on Zora</a>");
            $(mint_form).find('.mint-button').text("MINT AGAIN");

        } catch (e) {

            const isInsufficientFundsError = (e instanceof InsufficientFundsError)

            if(e.name == "ConnectorNotFoundError") { // shouldn't happen at this stage really
                console.log("Wallet not connected")
                $(mint_form).find('.mint-msg').text("⚠️ Lost connection to wallet. Reload this page, tap Connect, and try again.");
            } else if (e.name == "TransactionExecutionError") { 
                $(mint_form).find('.mint-msg').text("⚠️ Minting cancelled.");
                console.log("User declined")
                console.log(e)
            } else if (e.name == "UserRejectedRequestError") { 
                console.log("User declined")
                console.log(e)
            } else if (e.name == "InsufficientFundsError" || isInsufficientFundsError) {
                if ($(mint_form).attr('data-collection-chain')=="zora") {
                    $(mint_form).find('.mint-msg').html("⚠️ Insufficient funds. <a href='https://bridge.zora.energy/'>Bridge some ETH to Zora</a>.");
                } else {
                    $(mint_form).find('.mint-msg').text("⚠️ Insufficient funds.");
                }
            } else if (e.name == "ContractFunctionExecutionError") {
                $(mint_form).find('.mint-msg').html("⚠️ Can't mint this. Please message <a href='https://twitter.com/fascinated'>@fascinated</a> about this.");
                console.log(e)
            } else {
                $(mint_form).find('.mint-msg').text("⚠️ Could not mint comment: " + e.name);
                console.log(e)
            }

            $(elt).removeClass("disabled");
            return false;

        }

        $(elt).removeClass("disabled");

    };

    window.mint_cancel_click = function (e) {

        let elt = e.target
        let mint_form = elt.parentNode.parentNode;

        $(mint_form).removeClass("active");
        $(mint_form).find('.mint-button').text("MINT THIS MOMENT");
        $('.current-token').show();

    };

